.blogCardWrapper {
  position: relative;

  &-image {
    height: 22rem;
    overflow: hidden;
    @include responsive-for(md) {
      height: 16rem;
    }
    img {
      height: 22rem;
      width: 100%;
      object-fit: cover;

      @include responsive-for(md) {
        height: 16rem;
      }
    }
  }
  &-title {
    position: absolute;
    bottom: 1%;
    z-index: 2;
    left: 0;
    padding: 1rem;
    h1 {
      color: white;
      font-size: 18px !important;
      font-weight: 500 !important;
    }

    &-btn {
      color: color(primary, base) !important;
      text-align: right;
      text-decoration: underline !important;
    }
  }
  &-cat {
    position: absolute;
    color: white;
    background: color(primary, base);
    display: inline-block;
    padding: 0.2rem 0.5rem;
    margin-top: 0.5rem;
    top: 1%;
    left: 3%;
    z-index: 9999;
  }
  &-overlay {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 21.88%,
      rgba(42, 42, 42, 0.80514) 73.84%,
      #000000 92.52%
    );
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

//blog holder section

.blogHolder {
  &-title {
    background: color(primary, tint);
    text-align: center;
    padding: 4rem;
    border-radius: $radius;
    margin-top: 1rem;
    color: color(primary, base);
  }
  &-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3rem;
    @include responsive-for(sm) {
      display: block;
    }
    select {
      width: 20%;
      @include responsive-for(sm) {
        width: 70%;
      }
    }
  }

  &-pills {
    margin: 2rem 0;
    span {
      background: rgb(235, 235, 235);
      padding: 0.5rem 0.6rem;
      margin-right: 1rem;
      border-radius: 4px;
      .btn-close {
        margin-left: 0.5rem;
        font-size: 10px;
      }
    }
  }
}

//blog page

.blogContainer {
  margin-top: 3rem;
  img {
    max-height: 100vh;
    object-fit: cover;
    width: 100%;
  }

  .allBolgs {
    text-align: right;
    // span {
    //   text-decoration: underline;
    // }
  }
}

.news-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-decoration: none;
  color: black;
  box-shadow: 1px 1px 12px rgba(74, 74, 74, 0.275);
  padding: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  @include responsive-for(sm) {
    margin-bottom: 2rem;
  }
  &:hover {
    color: #000000 !important;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 6px;
  }

  .news-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .news-card__title {
    font-size: 1.2rem;
    color: color(text-light);
    margin-bottom: 10px;
    height: 4rem;
    overflow: hidden;
    @include truncate();
    -webkit-line-clamp: 3;
    &:hover {
      text-decoration: underline;
      color: #000000 !important;
    }
  }

  .news-card__description {
    font-size: 1em;
    color: gray;
  }
}
