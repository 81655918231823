.loader {
  display: flex;
  justify-content: center;
  // height: 10rem;
}

.loader__item {
  width: 16px;
  height: 16px;
  margin: 4px;
  background: rgb(179, 179, 179);
  border-radius: 50%;
  list-style: none;
  animation: animation-loading 1.2s infinite;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }
  &:nth-child(2) {
    animation-delay: -0.15s;
  }
}

@keyframes animation-loading {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

///skeleton animaton
///
///
///
///
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;
  border-radius: 6px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0),
      rgba(#fff, 0.5),
      rgba(#fff, 0)
    );
    background-size: 100px 100%;
    background-repeat: no-repeat;
    animation: shimmer 1s ease infinite;

    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

// OBJECTS
