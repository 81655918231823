.membersWrapper {
  .memberCard {
    margin-bottom: 1.5rem;
    &-image {
      width: 100%;
      height: 25rem;
      object-fit: cover;
      border-radius: 6px;
      @include responsive-for(sm) {
        height: 20rem;
      }
    }

    &-name {
      font-weight: 500;
      font-size: 22px;

      margin-top: 1rem;
      margin-bottom: 0;
      @include responsive-for(sm) {
        font-size: 20px;
      }
    }
    &-description {
      color: rgb(150, 150, 150);
    }
  }
}

.noMembers {
  text-align: center;
  color: rgb(176, 176, 176);
  font-weight: 300;
  font-size: 1.5rem;
}

.locationDropdown {
  padding: 0.5rem 0.8rem;
  font-size: 14px;
  font-weight: 400;
  color: black;
  transition: all 0.2s ease-in;
  &:hover {
    background: color(primary, base);
    color: white;
  }
}

.GalleryWrapper {
  margin-top: 4rem;
  .galleryItem {
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: 18rem;
      object-fit: cover;
    }
  }
}

.whatWrapper {
  margin-top: 5rem;
  .whatCard {
    box-shadow: 1px 2px 6px rgb(220, 220, 220);
    padding: 1.5rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 2rem;

    &-icon {
      border: $border;
      padding: 0.1rem 1rem;
      display: inline-block;
      margin-bottom: 1.5rem;
      font-size: 3rem;
      color: color(primary, base);
      border-color: color(primary, base);
      border-radius: 6px;
    }

    &-title {
      margin-bottom: 1rem;
    }
    &-desc {
      color: color(text-light);
      height: 7rem;
    }
  }
}

.customDwn {
  background: rgb(255, 255, 255) !important;
  border: none !important;
  color: black !important;
  &:hover {
    color: color(primary, base) !important;
  }
}

.first_about_main_container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('../assets/images/membershero.png'),
    url('../assets/images/membershero.png');
  background-size: cover;
  padding: 6rem 0;
  .first_about_container {
    text-align: center;
    .first_about_header {
      font-size: 56px;
      font-weight: $font-bold;
      color: white;
      text-shadow: 1px 3px 6px rgb(31, 31, 31);
      text-transform: uppercase;
      @include responsive-for(sm) {
        font-size: 32px;
      }
    }
    .first_about_second_header {
      font-size: 32px;
      font-weight: $font-bold;
      color: white;
      .ls_about {
        font-size: 35px;
        color: color(primary, base);
      }
    }
    .first_about_desc {
      margin-top: 2rem;
      font-size: $text-lg;
      color: white;
    }
    // .first_about_btn {
    //   margin-top: 3.5rem;
    //   button {
    //     @include btnPrimary;
    //     width: 189px !important;
    //     &:hover::after {
    //       width: 189px !important;
    //     }
    //   }
    // }
  }
}

// .membersWrapper {
//   margin-top: 5rem;
//   .searchMember {
//     margin-top: 3rem;
//     margin-bottom: 1rem;
//     input {
//       padding: 0.5rem;
//       border: 1px solid rgb(158, 158, 158);
//       border-radius: 4px;
//       width: 30%;
//       @include responsive-for(sm) {
//         width: 100%;
//       }
//     }
//   }
// }
.membercard {
  margin-top: 2rem;
  background: white;
  box-shadow: 1px 3px 8px 1px rgba(78, 78, 78, 0.16);
  padding: 1.5rem 1rem;
  min-height: 14rem;
  transition: transform 0.15s ease-in;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    margin-top: 2rem;
    height: 4rem;
    object-fit: contain;
  }
  figcaption {
    margin-top: 2rem;
    text-align: center;
    color: color(text-light);
    height: 3.2rem;
    overflow: hidden;
  }
}

//@at-root

.interestedWrapper {
  margin-top: 5rem;
  background: color(primary, base);
  display: flex;
  // @include responsive-for(md) {
  //   margin-bottom: 20rem;
  // }
  &-image {
    width: 50%;
    margin: 0;
    position: relative;
    @include responsive-for(md) {
      width: 100%;
    }
    img {
      width: 100%;
    }

    &-red {
      height: 100%;
      @include responsive-for(md) {
        display: none;
      }
    }

    &-desc {
      position: absolute;
      top: 15%;
      padding: 2rem;
      right: -70%;
      border: 2px solid white;
      width: 50rem;
      background: rgba(47, 72, 88, 0.92);
      @include responsive-for(md) {
        width: auto;
        position: static;
        padding: 1rem;
      }
      h1 {
        color: white !important;
        text-transform: uppercase;
      }
      p {
        color: white;
        line-height: 200%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 300;
      }
    }
  }
}

//membership form

.applicationHolder {
  margin-top: 2rem;
  border: $border;
  padding: 1rem;
  h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
  }
}

.noImage {
  text-transform: uppercase;
  display: grid;
  place-items: center;
  width: 100%;
  font-size: 4rem;
  font-weight: 600;
  background: color(primary, tint);
  color: color(primary, base);
}

.secondaryHero {
  background: color(primary, base);
  margin-bottom: 5rem;
  @include responsive-for(sm) {
    margin-bottom: 3rem;
  }
  h2 {
    color: white;
    text-align: center;
    padding: 5rem;
    font-size: 4rem !important;
    font-weight: 500 !important;
    @include responsive-for(sm) {
      font-size: 2rem !important;
      padding: 2rem;
    }
  }
}
